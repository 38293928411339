import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: {
      de
    },
    current: 'de'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#999999',
        secondary: '#666666',
        error: '#801616'
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})
