
<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      class="m-0 p-0"
      persistent
      max-width="290"
    >
      <v-alert
        class="p-5 m-0"
        color="pink"
        dark
        icon="fad fa-alarm-exclamation"
        transition="scale-transition"
      >
        <div class="m-0">
          Sie waren länger als 60 Minuten inaktiv und werden in
          {{ time }} Sekunden automatisch ausgeloggt
        </div>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 3600000,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false
    }
  },
  created () {
    const timerId = setInterval(() => {
      this.time -= 1000
      console.log(this.time)
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)

      if (this.time < 1) {
        clearInterval(timerId)
        // Your logout function should be over here
        sessionStorage.removeItem('token')
        alert('Ihre Sitzung wurde beendet.')
      }
    }, 1000)
  }
}
</script>
