<template>
  <Weihnachtsmarkt />
</template>

<script>

import Weihnachtsmarkt from '@/components/kalender/weihnachtsmarkt/Index.vue'
// import Weihnachtsmarkt from "@/components/kalender/weihnachtsmarkt/V2/Index.vue";
export default {
  name: 'KalenderComponent',
  components: {
    Weihnachtsmarkt
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
