import axios from 'axios'
// console.log('store is here : login');
const state = {
  token: sessionStorage.getItem('token') || ''
}
const mutations = {
  GET_TOKEN_MUTATION (state, payload) {
    state.token = payload
    // console.log(payload);
  }
}
const actions = {
  getTokenAction ({
    commit
  }, payload) {
    // console.log("Value: ", payload);

    commit('GET_TOKEN_MUTATION', payload)

    // console.log('Payload: '+payload);

    return axios
      .get('/api/onlineantrag/settoken/' + payload)
      .then((response) => {
        // console.log(response);
        if (response.data.token) {
          // console.log('Hallo ich Token: '+ response.data.token);
          const token = response.data.token
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('vermittler', JSON.stringify(response.data.vermittler))
          state.token = token
        } else {
          // console.log('nix Token');
        }
        /* if (response.data.user) {
                    const user = response.data.user.firstName + ' ' + response.data.user.lastName;
                    sessionStorage.setItem('user', user)
                    state.user = user
                }
                if (response.data.acl) {
                    const token = response.data.acl.Applikationen_finasStatistics
                    sessionStorage.setItem('token', JSON.stringify(token))
                    state.token = token
                    axios.defaults.headers.common['Authorization'] = token
                    commit('GET_ACL_DATA', {
                        items: {

                            'data': response.data,
                        },
                    });
                } else {
                    commit('GET_ACL_DATA', {
                        items: {

                            'message': response.data.message,
                        },
                    });
                } */
      }).catch(error => {
        commit('GET_ACL_DATA', {
          items: error
        })
        sessionStorage.removeItem('token')
      })
  }
  /* logOut({
        commit
    }, payload) {

        commit('LOG_OUT', payload);
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        commit('LOG_OUT', '');

    } */

}
const getters = {
  getTokenGetter: state => state.token
}

const loginModule = {
  state,
  mutations,
  actions,
  getters
}

export default loginModule
