<!-- eslint-disable no-array-constructor -->
<!-- eslint-disable no-array-constructor -->
<!-- eslint-disable no-array-constructor -->
<template>
  <div>
    <span class="bg" />
    <v-responsive
      style="min-height: calc(100vh - 10px)"
      :aspect-ratio="16 / 9"
    >
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            class="text-center"
            cols="12"
            sm="12"
            md="9"
          >
            <v-main>
              <v-card v-show="login == false ? true : false">
                <v-card-title>
                  Login<v-spacer />
                  <v-btn
                    elevation="6"
                    fab
                    right
                    top
                    class="red darken-4 white--text"
                    @click="userLogin()"
                  >
                    <v-icon>fa fa-sign-in</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-alert
                    v-show="loginMessage ? true : false"
                    type="error"
                    dense
                  >
                    {{ loginMessage }}
                  </v-alert>
                  <v-text-field
                    v-model="username"
                    label="Benutzername"
                    @keyup.enter="userLogin()"
                  />
                  <v-text-field
                    v-model="password"
                    label="Passwort"
                    type="password"
                    @keyup.enter="userLogin()"
                  />
                </v-card-text>
              </v-card>
              <v-card
                v-show="login == true ? true : false"
                elevation="2"
                class="cardTransparent"
              >
                <v-card-title>
                  <h1 class="text-h2 pt-5">
                    <v-icon
                      x-large
                      left
                      class="mb-3 mr-5"
                    >
                      fad fa-calendar-star
                    </v-icon>Weihnachtsmarkt <span class="font-weight-black">{{ currenYear }}</span>
                  </h1>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="login == true ? true : false"
                        elevation="6"
                        v-bind="attrs"
                        fab
                        right
                        top
                        class="red darken-4 white--text"
                        v-on="on"
                        @click="userLogout()"
                      >
                        <v-icon>fa fa-power-off</v-icon>
                      </v-btn>
                    </template>
                    <span>Logout</span>
                  </v-tooltip>
                </v-card-title>

                <v-card-subtitle
                  v-if="loginData.login == true"
                  class="text-right"
                >
                  eingeloggt als:
                  <strong>{{ loginData.user.vorname }}
                    {{ loginData.user.nachname }}</strong><v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        class="ml-4"
                        elevation="0"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="dialogColor = true"
                      >
                        <v-icon x-small>
                          fad fa-palette
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Farbe Ihrer Termine ändern</span>
                  </v-tooltip>
                </v-card-subtitle>
                <v-dialog
                  v-model="dialogColor"
                  max-width="600"
                >
                  <v-card>
                    <v-container>
                      <v-card-title
                        class="primary mb-2"
                      >
                        Farbe Ihrer Termine anpassen
                      </v-card-title>
                      <v-card-text class="p-3">
                        <v-color-picker
                          v-model="colorPickerDefault"
                          dot-size="25"
                          swatches-max-height="200"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          class="mr-4"
                          text
                          tile
                          outlined
                          elevation="0"
                          @click="dialogColor = false"
                        >
                          schliessen
                        </v-btn>
                        <v-btn
                          text
                          tile
                          elevation="0"
                          color="success"
                          class="mr-4"
                          @click="
                            setUserConfig();
                            dialogColor = false;
                          "
                        >
                          Terminfarbe anpassen
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="mouseEnterDay"
                  max-width="600"
                >
                  <v-card>
                    <v-container>
                      <v-card-text class="p-3">
                        An diesem Tag kann kein Termin gesetzt werden.
                      </v-card-text>
                    </v-container>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialogDate"
                  max-width="600"
                >
                  <v-card>
                    <v-container>
                      <v-card-title
                        class="primary mb-2"
                      >
                        Termin am: {{ dateEvent }} von: {{ rangeTime[0] }}:00 -
                        {{ rangeTime[1] }}:00
                      </v-card-title>
                      <v-card-text class="p-3">
                        <v-range-slider
                          v-model="rangeTime"
                          :max="max"
                          :min="min"
                          thumb-label="always"
                          :thumb-color="rangeThumbColor"
                          dense
                          label="Uhrzeit: "
                          step="1"
                          :tick-labels="rangeLabels"
                          class="mt-5"
                          @change="checkRange()"
                        />

                        <v-text-field
                          v-model="dateEvent"
                          type="hidden"
                        />
                        <v-card-title>Auslastung</v-card-title>
                        <span v-html="messageTerminDialog" />
                        <v-sheet
                          color="grey darken-2"
                          class="p-3"
                        >
                          <v-sparkline
                            :labels="config.calendar.sparkline.labels"
                            :value="config.calendar.sparkline.values"
                            line-width="10"
                            padding="5"
                            :gradient="['white']"
                            type="bar"
                          >
                            <template v-slot:label="item">
                              {{ item.value }}
                            </template>
                          </v-sparkline>
                        </v-sheet>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          class="mr-4"
                          text
                          tile
                          outlined
                          elevation="0"
                          @click="dialogDate = false"
                        >
                          schliessen
                        </v-btn>
                        <v-btn
                          color="success"
                          text
                          tile
                          elevation="0"
                          class="mr-4"
                          :disabled="disableTerminSetting"
                          @click="
                            setTermin();
                            dialogDate = false;
                          "
                        >
                          Termin eintragen
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-dialog>
                <v-row>
                  <v-col
                    cols="12"
                    xl="8"
                  >
                    <v-sheet
                      tile
                      height="54"
                      class="d-flex"
                    >
                      <v-btn
                        icon
                        class="ma-2"
                        @click="$refs.calendar.prev()"
                      >
                        <v-icon>fal fa-chevron-left</v-icon>
                      </v-btn>
                      <v-select
                        v-model="type"
                        :items="types"
                        item-text="label"
                        item-value="value"
                        dense
                        outlined
                        hide-details
                        class="ma-2"
                        label="Kalenderansicht"
                      />

                      <v-btn
                        icon
                        class="ma-2"
                        @click="$refs.calendar.next()"
                      >
                        <v-icon>fal fa-chevron-right</v-icon>
                      </v-btn>
                    </v-sheet>
                    <v-sheet height="700">
                      <v-calendar
                        v-if="config"
                        ref="calendar"
                        v-model="value"
                        :type="type"
                        :events="events"
                        :event-more="true"
                        :event-height="18"
                        :event-margin="0"
                        :event-color="getEventColor"
                        :start="config.calendar.beginn"
                        :end="config.calendar.ende"
                        first-interval="11"
                        interval-count="9"
                        event-overlap-mode="column"
                        @click:date="setDialogDate"
                        @click:day="setDialogDate"
                        @click:more="viewDay"
                      />
                    </v-sheet>
                  </v-col>
                  <v-col
                    cols="12"
                    xl="4"
                  >
                    <v-card
                      flat
                      tile
                    >
                      <v-toolbar
                        dense
                        dark
                      >
                        <v-toolbar-title>Stundenbelegung</v-toolbar-title>

                        <v-spacer />

                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-list
                        dense
                        nav
                      >
                        <v-list-group
                          v-for="item in countHours()"
                          :key="item.title"
                          v-model="item.active"
                          :prepend-icon="item.action"
                          no-action
                        >
                          <template v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-left"
                                :style="'color:' + item.color + '; '"
                              >
                                <v-row>
                                  <v-col class="py-0">
                                    {{ item.title }}
                                  </v-col>
                                  <v-col class="py-0">
                                    {{ item.hours }} Stunden
                                  </v-col>
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <v-card-text>
                            <template>
                              <v-data-table
                                :headers="item.tableHeaders"
                                :items="item.content"
                                :items-per-page="-1"
                                class="elevation-1"
                                sort-by="datum"
                              >
                                <template v-slot:item.datum="{ item }">
                                  <span>{{
                                    new Date(item.datum).toLocaleDateString(
                                      "de-DE",
                                      {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }
                                    )
                                  }}</span>
                                </template>
                              </v-data-table>
                            </template>
                          </v-card-text>
                        </v-list-group>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-snackbar
                  v-model="snackbar"
                  :multi-line="true"
                >
                  {{ terminMessage }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      :color="snackbarColor"
                      text
                      fab
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      <v-icon>fa fa-times</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-card>
            </v-main>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
moment.locale('de')

export default {
  components: {},
  data: () => ({
    kalenderId: 10, // siehe Datenbank
    currenYear: 2023,
    disabledDates: ['2412', '2512'], // TagMonat, an welchen keine Termine eingetragen werden können
    min: 0,
    max: 0,
    step: 1,
    rangeTime: [11, 12],
    rangeTimeLast: [11, 12],
    rangeLabels: [],
    date: '',
    name: '',
    dateEvent: '',
    dateEventRaw: '',
    focus: '',
    color: '',
    dialogDate: false,
    dialogColor: false,
    username: '',
    password: '',
    login: false,
    loginData: { login: false, user: '' },
    events: [],
    config:
      {
        calendar: {
          beginn: '2023-11-27',
          ende: '2023-12-29',
          valuesDefault: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          sparkline: {

            labels: ['11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22'],
            values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          wochentage: {
            name: {
              1: 'Mo',
              2: 'Di',
              3: 'Mi',
              4: 'Do',
              5: 'Fr',
              6: 'Sa',
              0: 'So'
            },
            besetzungWochentag: {
              1: {
                11: 1,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              2: {
                11: 1,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              3: {
                11: 1,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              4: {
                11: 1,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              5: {
                11: 1,
                12: 1,
                13: 1,
                14: 1,
                15: 1,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              6: {
                11: 1,
                12: 1,
                13: 1,
                14: 2,
                15: 2,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              },
              0: {
                11: 1,
                12: 1,
                13: 1,
                14: 2,
                15: 2,
                16: 2,
                17: 2,
                18: 2,
                19: 2,
                20: 2,
                21: 2,
                22: 2

              }
            }
          }

        }

      },
    loginMessage: '',
    disableTerminSetting: false,
    terminMessage: '',
    messageTerminDialog: '',
    snackbar: false,
    snackbarColor: 'green',
    userConfigColor: 'red darken-4',
    colorPickerDefault: '#B71C1C',
    rangeThumbColor: '',
    value: '',
    mouseEnterDay: false,
    type: 'custom-weekly',
    types: [
      {
        value: 'custom-weekly',
        label: 'Gesamt'
      },
      {
        value: 'week',
        label: 'Woche'
      },
      {
        value: 'day',
        label: 'Tag'
      }
    ]
  }),

  created () {
    // console.log("created");
    if (!sessionStorage.getItem('login')) {
      this.loginData.login = this.login
      sessionStorage.setItem('login', JSON.stringify(this.loginData))
    } else {
      this.loginData = JSON.parse(sessionStorage.getItem('login'))
      this.login = this.loginData.login
    }

    if (!sessionStorage.getItem('events')) {
      sessionStorage.setItem('events', JSON.stringify(this.events))
    } else {
      this.events = JSON.parse(sessionStorage.getItem('events'))
    }

    axios
      .get(
        '/api/Veranstaltungskalender/Veranstaltungskalender/getConfig/?id=' +
          this.kalenderId
      )
      .then((response) => {
        // let url = '/api/Vergleichsrechner/Rechner/getVertragsdokumente/';
        // console.log(response);
        if (response.data) {
          if (response.data.data) {
            this.events = response.data.data.events

            sessionStorage.setItem('events', JSON.stringify(this.events))
          }
        }
      })
      .catch((error) => {
        console.log('ERRROR:' + error)
      })
  },

  methods: {
    viewDay (day) {
      this.date = day.date
      this.value = day.date
      this.type = 'day'
      // console.log(day);
      // console.log(this);
    },
    allowedStep: (m) => m % 60 === 0,

    setDialogDate ({ date, day, month }) {
      if (
        date < this.config.calendar.beginn ||
        date > this.config.calendar.ende
      ) {
        return false
      }

      // this.config.calendar.sparkline.values = [0, 0, 0, 0, 0, 0, 0, 0, 0];

      let disabled = false

      this.disabledDates.forEach((element) => {
        if (element === day + '' + month) {
          disabled = true
        }
      })

      this.dateEvent = moment(date).format('LL')
      this.dateEventRaw = date
      // console.log(this.dateEventRaw);

      this.config.calendar.sparkline.values = this.checkMaxTerminPerDay()
      // console.log(this.config.calendar.sparkline.values)
      this.checkRange()

      if (disabled === false) {
        this.dialogDate = true
        this.mouseEnterDay = false
      } else {
        this.mouseEnterDay = true
      }
    },

    getEventColor (event) {
      return event.color
    },

    countHours () {
      const out = {}
      let hour = 0
      this.events.forEach((event) => {
        let arr = event.name.split(' ')[0]
        const nameArr = event.name.split(' ')
        arr = arr.split('-')

        hour = arr[1] - arr[0]

        const eventStart = event.start.split(' ')
        const eventEnd = event.end.split(' ')
        if (out[event.benutzerId]) {
          out[event.benutzerId].hours = out[event.benutzerId].hours + hour

          out[event.benutzerId].content[event.id] = {}

          out[event.benutzerId].content[event.id].datum = eventStart[0]
          out[event.benutzerId].content[event.id].start =
            eventStart[1] + ' Uhr'
          out[event.benutzerId].content[event.id].ende = eventEnd[1] + ' Uhr'
          out[event.benutzerId].content[event.id].anzahl =
            eventEnd[1].split(':')[0] - eventStart[1].split(':')[0]
        } else {
          out[event.benutzerId] = {}
          out[event.benutzerId].tableHeaders = [
            { value: 'datum', text: 'Datum' },
            { value: 'start', text: 'Beginn' },
            { value: 'ende', text: 'Ende' },
            { value: 'anzahl', text: 'Stunden' }
          ]
          out[event.benutzerId].color = event.color
          out[event.benutzerId].hours = hour
          out[event.benutzerId].title = nameArr[1] + ' ' + nameArr[2]
          out[event.benutzerId].action = 'fa fa-user'

          out[event.benutzerId].content = {}
          out[event.benutzerId].content[event.id] = {}

          out[event.benutzerId].content[event.id].datum = eventStart[0]
          out[event.benutzerId].content[event.id].start =
            eventStart[1] + ' Uhr'
          out[event.benutzerId].content[event.id].ende = eventEnd[1] + ' Uhr'
          out[event.benutzerId].content[event.id].anzahl =
            eventEnd[1].split(':')[0] - eventStart[1].split(':')[0]
        }
        let objTest = {}
        objTest = Object.values(out[event.benutzerId].content)
        out[event.benutzerId].content = objTest
      })

      return out
    },

    checkMaxTerminPerDay () {
      const range = {}
      const r = []

      this.config.calendar.valuesDefault.forEach((valuesDefault) => {
        r.push(valuesDefault)
      })
      range.eventCount = 0
      const datum = this.dateEventRaw
      // console.log(r)
      // console.log(this.config.calendar.valuesDefault)

      let startzeit = ''
      let endzeit = ''
      this.events.forEach((event) => {
        if (datum === event.start.split(' ')[0]) {
          range.eventCount++
          startzeit = event.start.split(' ')[1].split(':')[0]
          endzeit = event.end.split(' ')[1].split(':')[0]
          // console.log(r)
          // console.log(endzeit)
          for (let x = startzeit; x < endzeit; x++) {
            // 11 Uhr ist Beginn, daher müssen aus dem 10 stelligen Array -11 abgezogen werden

            r[x - 11]++
            if (range[x]) {
              range[x]++
            } else {
              range[x] = 1
            }
          }
        }
      })
      return r
    },

    setTermin () {
      const payload = {}
      payload.kalenderId = this.kalenderId
      payload.benutzerId = this.loginData.user.id
      payload.datum = this.dateEventRaw
      payload.zeitVon = this.rangeTime[0]
      payload.zeitBis = this.rangeTime[1]

      axios

        .post(
          '/api/Veranstaltungskalender/Veranstaltungskalender/setTermin/',
          JSON.stringify(payload)
        )
        .then((response) => {
          // let url = '/api/Vergleichsrechner/Rechner/getVertragsdokumente/';

          if (response.data) {
            if (response.data.data.events) {
              this.events = response.data.data.events
              sessionStorage.setItem('events', JSON.stringify(this.events))
              this.terminMessage = response.data.data.state.message
              if (response.data.data.state.error === '1') {
                this.snackbarColor = 'red'
              }
            } else {
              this.terminMessage = 'Termin konnte nicht gespeichert werden'
              this.snackbarColor = 'red'
            }
          } else {
            this.terminMessage = 'Speichern des Termins fehlgeschlagen'
            this.snackbarColor = 'red'
          }
        })
        .catch((error) => {
          console.log('ERROR:' + error)
        })

      this.snackbar = true
    },

    checkRange () {
      const rangeTimeCheckRange = this.rangeTime

      // console.log(this.config.calendar.sparkline.values);
      // console.log(this.rangeTime + " - " + this.rangeTimeLast);
      // console.log(rangeTime[0] + ":::" + rangeTime[1]);
      const weekday = moment(this.dateEventRaw).format('dddd')
      const weekdayNumber = moment(this.dateEventRaw).format('d')
      const besetzungAktuellerTag = this.config.calendar.wochentage.besetzungWochentag[weekdayNumber]
      const min = Object.keys(besetzungAktuellerTag)[0]
      let max = Object.keys(besetzungAktuellerTag).pop()
      this.min = min
      this.max = max

      if (this.dateEventRaw === '2023-12-29') {
        max = 18
      }
      console.log(max)

      this.rangeLabels = []

      for (const prop in besetzungAktuellerTag) {
        this.rangeLabels.push(prop)
      }

      // console.log(this.rangeLabels)

      if (rangeTimeCheckRange[0] > rangeTimeCheckRange[1]) {
        rangeTimeCheckRange[0] = rangeTimeCheckRange[1] - 1
      }
      if (rangeTimeCheckRange[1] < rangeTimeCheckRange[0]) {
        rangeTimeCheckRange[1] = rangeTimeCheckRange[0] + 1
      }
      // console.log(rangeTimeCheckRange[0] + ':::' + rangeTimeCheckRange[1])

      let rangeTimeLast = this.rangeTimeLast
      // console.log(rangeTimeLast);
      let rangeMin = 1 // Anzahl der Mindesstunden
      // console.log(rangeMin)
      rangeTimeLast[1] = 19

      if (this.rangeTime[0] === this.rangeTime[1]) {
        this.rangeTime[0]--
        if (this.rangeTime[0] < this.rangeLabels[0]) {
          this.rangeTime[0]++
          this.rangeTime[1] = this.rangeTime[0] + besetzungAktuellerTag[this.rangeTime[1]]
        }
      }

      this.messageTerminDialog = ''

      if (!rangeTimeLast[0] && !rangeTimeLast[1]) {
        rangeTimeLast = rangeTimeCheckRange
      }

      if (rangeTimeCheckRange[0] !== rangeTimeLast[0]) {
        // Beginn ändert sich
        // console.log('Beginn ändert sich')

        if (rangeTimeCheckRange[0] % 2 === 0) {
          // rangeTime[0] = rangeTime[0] + 1;
        }
        if (rangeTimeCheckRange[0] > 17 && rangeTimeCheckRange[1] === rangeTimeCheckRange[0] + 1) {
          rangeMin = 2
        }

        if (weekday === 'Freitag' || weekday === 'Samstag') {
          if (
            rangeTimeCheckRange[0] >= 17
          ) {
            rangeMin = 3
          }
        }
        // console.log(this.rangeTime[0] + ' ' + this.rangeTime[1] + ' ' + rangeMin)
        // console.log(rangeTimeCheckRange[0] + ' ' + rangeTimeCheckRange[1] + ' ' + rangeMin)
        if (rangeMin === 2 || rangeMin === 3) {
          if (rangeTimeCheckRange[1] <= rangeTimeCheckRange[0] + rangeMin) {
            rangeTimeCheckRange[1] = rangeTimeCheckRange[0] + rangeMin
          }
          if (rangeTimeCheckRange[1] >= max) {
            rangeTimeCheckRange[1] = max
            rangeTimeCheckRange[0] = max - rangeMin
          }
          // console.log(rangeTime[0] + " " + rangeTime[1]);
        }

        // console.log(rangeMin + " " + max);
      } else {
        // Ende ändert sich
        // console.log('Ende ändert sich')
        /*
        if (rangeTime[1] % 2 == 0) {
          rangeTime[1] = rangeTime[1] + 1;
        }
*/
        // console.log(rangeTime)
        // console.log(rangeTimeLast)

        if (weekday === 'Freitag' || weekday === 'Samstag') {
          if (rangeTimeCheckRange[1] > 17) {
            rangeMin = 3
            // rangeTimeCheckRange[1] = 21
            // console.log('fr or sa')
          }
        } else {
          if (rangeTimeCheckRange[1] > 17) {
            rangeMin = 2
            // rangeTimeCheckRange[1] = 21
            // console.log("so - do");
          }
          if (rangeTimeCheckRange[0] > rangeTimeCheckRange[1] - rangeMin) {
            rangeTimeCheckRange[1] = rangeTimeCheckRange[0] + rangeMin
          }
        }

        // console.log(rangeMin + ' ' + max)
        // console.log(rangeTimeCheckRange[0] + ' ' + rangeTimeCheckRange[1])
      }

      if (rangeMin === 2 || rangeMin === 3) {
        if (rangeTimeCheckRange[1] <= rangeTimeCheckRange[0] + rangeMin) {
          rangeTimeCheckRange[1] = rangeTimeCheckRange[0] + rangeMin
        }

        if (rangeTimeCheckRange[1] >= max) {
          rangeTimeCheckRange[1] = max
          rangeTimeCheckRange[0] = max - rangeMin
        }
        // console.log(rangeTime[0] + " " + rangeTime[1]);
      }

      if (rangeTimeCheckRange[1] > max) {
        rangeTimeCheckRange[1] = max
        rangeTimeCheckRange[0] = max - rangeMin
      }

      if (rangeTimeCheckRange[0] < this.min) {
        rangeTimeCheckRange[0] = this.min
        rangeTimeCheckRange[1] = this.min + rangeMin
      }
      // console.log(rangeTime[0] + ":::" + rangeTime[1]);
      this.rangeTime = [rangeTimeCheckRange[0], rangeTimeCheckRange[1]]
      // console.log(this.rangeTime);
      // 11 Uhr ist Beginn, daher müssen aus dem 10 stelligen Array -11 abgezogen werden

      // let anzGesamt = Math.ceil((parseInt(anzBeginn) + parseInt(anzEnde))/2);

      let anzGesamt = 0
      for (let anz = rangeTimeCheckRange[0]; anz <= rangeTimeCheckRange[1]; anz++) {
        anzGesamt = anzGesamt + this.config.calendar.sparkline.values[anz - 11]
      }

      anzGesamt = Math.ceil(anzGesamt / 2)

      max = 2 // 2 Personen
      // console.log(this.config.calendar.sparkline.values);

      // console.log(anzGesamt);
      // eslint-disable-next-line no-array-constructor
      let terminauslastungMaxValues = Array(1, 1, 1, 1, 1, 2, 2, 2, 2)
      if (
        weekday === 'Freitag' ||
        weekday === 'Samstag' ||
        weekday === 'Sonntag'
      ) {
        // eslint-disable-next-line no-array-constructor
        terminauslastungMaxValues = Array(1, 1, 1, 2, 2, 2, 2, 2, 2, 2)

        if (rangeTimeCheckRange[1] <= 14) {
          // zwei Personen in der Zeit von 11:00 Uhr bis 16:00
          max = 1
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 11:00 Uhr bis 14:00 Uhr sind <strong>' +
            max +
            ' Personen</strong>. '
        } else {
          // drei Personen in der Zeit von 11:00 Uhr bis 16:00
          max = 2
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 14:00 Uhr bis ' + rangeTimeLast[1] + ':00 Uhr sind <strong>' +
            max +
            ' Personen</strong>. '
        }
      } else {
        if (rangeTimeCheckRange[1] <= 16) {
          // eine Person in der Zeit von 11:00 Uhr bis 15:00
          max = 1
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 11:00 Uhr bis 16:00 Uhr ist <strong>' +
            max +
            ' Person</strong>. '
        } else {
          // zwei Personen in der Zeit von 15:00 Uhr bis 20:00
          max = 2
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 16:00 Uhr bis ' + rangeTimeLast[1] + ':00 Uhr sind <strong>' +
            max +
            ' Personen</strong>. '
        }
      }
      // Sonderfall
      if (this.dateEventRaw === '2023-12-29') {
        if (rangeTimeCheckRange[1] <= 16) {
          // eine Person in der Zeit von 11:00 Uhr bis 15:00
          max = 1
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 11:00 Uhr bis 16:00 Uhr ist <strong>' +
            max +
            ' Person</strong>. '
        } else {
          // zwei Personen in der Zeit von 15:00 Uhr bis 20:00
          max = 2
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 16:00 Uhr bis 18:00 Uhr sind <strong>' +
            max +
            ' Personen</strong>. '
        }
      }

      // Sonderfall
      if (this.dateEventRaw === '2022-12-26') {
        // eslint-disable-next-line no-array-constructor
        terminauslastungMaxValues = Array(1, 1, 1, 2, 2, 2, 2, 2, 2)
        if (rangeTimeCheckRange[1] <= 14) {
          // eine Person in der Zeit von 11:00 Uhr bis 15:00
          max = 1
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 11:00 Uhr bis 14:00 Uhr ist <strong>' +
            max +
            ' Person</strong>. '
        } else {
          // zwei Personen in der Zeit von 15:00 Uhr bis 20:00
          max = 2
          this.messageTerminDialog =
            'Die maximale Anzahl am ' +
            weekday +
            ' im Zeitraum<br>von 14:00 Uhr bis 19:00 Uhr sind <strong>' +
            max +
            ' Personen</strong>. '
        }
      }

      anzGesamt = this.config.calendar.sparkline.values[rangeTimeCheckRange[1] - 11]
      let letToBig = false

      // console.log(this.config.calendar.sparkline.values);
      // console.log(terminauslastungMaxValues);
      for (let x = rangeTimeCheckRange[0]; x < rangeTimeCheckRange[1]; x++) {
        // console.log(x);
        // console.log(x - 10);
        // console.log(
        //  this.config.calendar.sparkline.values[x - 11] +
        //    " - " +
        //    terminauslastungMaxValues[x - 11] +
        //    ": " +
        //    max
        // );

        if (
          this.config.calendar.sparkline.values[x - 11] >=
          terminauslastungMaxValues[x - 11]
        ) {
          letToBig = true
        }

        if (this.dateEventRaw === '2023-12-29') {
          // eslint-disable-next-line no-array-constructor
          terminauslastungMaxValues = Array(1, 1, 1, 1, 1, 2, 2, 0, 0, 0, 0, 0, 0)

          const xNew = x - 11
          if (
            this.config.calendar.sparkline.values[xNew] >=
          terminauslastungMaxValues[xNew]
          ) {
            letToBig = true
          }
          console.log(x + ' vs ' + xNew + ': ' + this.config.calendar.sparkline.values[xNew] + ' ..' + terminauslastungMaxValues[xNew] + ' ... ' + letToBig)
        }
      }

      // console.log(anzGesamt +':::'+max);
      if (letToBig) {
        this.rangeThumbColor = 'red'
        this.disableTerminSetting = true
      } else {
        this.rangeThumbColor = 'green'
        this.disableTerminSetting = false
      }

      this.messageTerminDialog =
        this.messageTerminDialog +
        'Aktuell ist der Stand im gewählten Intervall von ' +
        rangeTimeCheckRange[0] +
        ':00 Uhr - ' +
        rangeTimeCheckRange[1] +
        ":00 Uhr mit <strong class='" +
        this.rangeThumbColor +
        "--text'>" +
        this.config.calendar.sparkline.values[rangeTimeCheckRange[1] - 12] +
        '</strong> Personen belegt.'

      this.rangeTimeLast = this.rangeTime
      // console.log(this.rangeTime);
    },

    submit () {
      this.$refs.observer.validate()
    },

    setUserConfig () {
      this.loginMessage = ''
      const payload = {}
      payload.benutzerId = this.loginData.user.id
      payload.kalenderId = this.kalenderId
      payload.config = this.colorPickerDefault

      axios

        .post(
          '/api/Veranstaltungskalender/Veranstaltungskalender/setUserConfig/',
          JSON.stringify(payload)
        )
        .then((response) => {
          if (response.data) {
            if (response.data.data.events) {
              this.events = response.data.data.events
              sessionStorage.setItem('events', JSON.stringify(this.events))
              this.terminMessage = response.data.data.state.message
              if (response.data.data.state.error === '1') {
                this.snackbarColor = 'red'
              }
            } else {
              this.terminMessage =
                '0001: Die Konfiguration konnte nicht gespeichert werden'
              this.snackbarColor = 'red'
            }
          } else {
            this.terminMessage =
              '0002: Die Konfiguration konnte nicht gespeichert werden'
            this.snackbarColor = 'red'
          }
        })
        .catch((error) => {
          console.log(
            '0003: Config konnte nicht gespeichert werden: Fehler' + error
          )
        })
      this.snackbar = true
    },

    userLogin () {
      this.loginMessage = ''
      const payload = {}
      payload.name = this.username
      payload.geb = this.password

      axios

        .post(
          '/api/Veranstaltungskalender/Veranstaltungskalender/checkLogin/',
          JSON.stringify(payload)
        )
        .then((response) => {
          if (response.data) {
            if (response.data[0]) {
              this.login = true
              this.loginData.login = true
              this.loginData.user = response.data[0]
              sessionStorage.setItem('login', JSON.stringify(this.loginData))
            } else {
              this.loginMessage = 'bitte überprüfen Sie Ihre Zugangsdaten'
            }
          } else {
            this.loginMessage = 'Login fehlgeschlagen'
          }
        })
        .catch((error) => {
          console.log('ERRROR:' + error)
        })
    },
    userLogout () {
      this.login = false
      this.loginData.login = this.login
      this.loginData.user = ''
      sessionStorage.setItem('login', JSON.stringify(this.loginData))
    }
  }
}
</script>
<style scoped lang="scss">
.bg {
  background: url("/images/pexels-giftpunditscom-1303089.jpg") no-repeat center
    center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  background-size: cover;
  background-color: white;
  transform: scale(1.1);
}

.cardTransparent {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.cardTransparentComplete {
  background-color: rgba(255, 255, 255, 0) !important;
}
.v-list-item {
  min-height: 1px !important;
}
</style>
