<template>
  <v-app id="veranstaltungskalender">
    <!-- Must have the app property -->
    <v-card class="overflow-hidden">
      <v-app-bar
        app
        class="white"
      >
        <!--<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->

        <v-toolbar-title>Veranstaltungskalender</v-toolbar-title><v-spacer />
        <div
          id="logo-rotary"
          class="text-right"
        />
      </v-app-bar>
      <!--<v-navigation-drawer v-model="drawer" absolute left temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <router-link to="/">Home</router-link>
          </v-list-item>

          <v-list-item>
            <router-link to="/about">About</router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>-->
      <div style="width: 100%">
        <Timer v-if="isIdle" />
        <router-view />
      </div>
    </v-card>
    <!--<v-footer app> FOOTER </v-footer>-->
  </v-app>
</template>

<script>
/* Logout Timer: https://jakzaizzat.com/idle-timer-vue */

import Timer from '@/components/general/Timer.vue'

import Vue from 'vue'
import store from './store'
import IdleVue from 'idle-vue'
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3540000, // 59min,
  startAtIdle: false
})

export default {
  name: 'Veranstaltungskalender',
  components: {
    Timer
  },

  data: () => ({
    drawer: false,
    group: null
  }),

  computed: {
    isIdle () {
      // console.log(this.$store.state.idleVue.isIdle);
      return this.$store.state.idleVue.isIdle
    }
  },

  watch: {
    group () {
      this.drawer = false
    }
  }
}
</script>

<style lang="scss">
*,
*:focus,
*:hover,
*:active {
  outline: none !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
.v-text-field .v-input__slot {
  cursor: pointer !important;
}

.fade-enter-active {
  transition: opacity 3s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

#logo-rotary {
  background: url("/images/logo_rotary_lutherstadt-wittenberg.jpg") no-repeat
    center;
  height: 100%;
  width: 310px;
  display: inline-block;
  background-size: 85%;
  position: absolute;
  right: 0;
}
</style>
