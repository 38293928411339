<template>
  <Kalender />
</template>

<script>
import Kalender from '@/components/Kalender.vue'

export default {
  name: 'Home',

  components: {
    Kalender
  }
}
</script>
